import { ErrorResponseData } from '@kvika/api-client';
import {
  CapitalMarketsOnboardingResponseSchema,
  ProfessionalCategoryEnum,
  InvestorTypeEnum,
  DocumentType,
  DisruptionTypeEnum,
} from '@kvika/api-types';
import { ButtonBackgroundType } from '@kvika/button';
import { ModalSize } from '@kvika/modal';
import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import {
  Maybe,
  OnboardingPageBodyNavigationPrimary,
  OnboardingPageBodyModalgroupFields,
  OnboardingPageBody,
  PageNotFoundBody,
  Scalars,
} from './PrismicTypes';

// eslint-disable-next-line
export type UnknownObject = { [key: string]: any };

export enum SelectedTabType {
  INDIVIDUAL_TYPE = 0,
  LEGAL_ENTITY_TYPE = 1,
}

export enum ModalTypes {
  PROFESSIONAL = 'PROFESSIONAL',
  RETAIL = 'RETAIL',
  DOCUMENT_SIGNING = 'DOCUMENT_SIGNING',
  ELIGIBLE_COUNTERPARTY = 'ELIGIBLE_COUNTERPARTY',
  DOCUMENT_SIGNING_SUCCEEDED = 'DOCUMENT_SIGNING_SUCCEEDED',
  LOGIN_PHONE_EID = 'LOGIN_PHONE_EID',
  INAPPROPRIATE_FUND = 'INAPPROPRIATE_FUND',
  RELINQUISH_RIGHTS = 'RELINQUISH_RIGHTS',
}

export type FileUploadState = {
  key: string;
  files: File[];
  documentType: string;
  category: string;
  requiresUpload: boolean;
};

export type NextPageProps =
  | {
      onboardingData: CapitalMarketsOnboardingResponseSchema;
      isBeneficialOwnersMismatch?: never;
    }
  | {
      onboardingData?: never;
      isBeneficialOwnersMismatch: boolean;
    };

export type SliceState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError(error: any): void;
  goToNextPage(props?: NextPageProps): void;
  goToPreviousPage(): void;
  navigationPrimary?: Maybe<OnboardingPageBodyNavigationPrimary>;
  modalGroupFields?: Maybe<OnboardingPageBodyModalgroupFields[]>;
};

export interface ErrorData {
  data?: ErrorResponseData;
  status?: number;
  headers?: RawAxiosResponseHeaders | AxiosResponseHeaders;
}

export type ModalProps = { showModal?: boolean; modalText?: string; modalHeaderText?: string };

export type Slice = OnboardingPageBody | PageNotFoundBody;

export type OnboardingStatusReturnValue = {
  isComplete: boolean;
  investorType?: InvestorTypeEnum;
  isSubmitted: boolean;
};

export type ModalState = {
  modalContent?: OnboardingPageBodyModalgroupFields;
  onConfirm?(): void;
  onClose?(): void;
  onConfirmButtonStyle?: ButtonBackgroundType;
  onCancelButtonStyle?: ButtonBackgroundType;
  closeOnClickOutside?: boolean;
  showCloseButton?: boolean;
  testId?: string;
  autoFocus?: boolean;
  size?: ModalSize;
};

export enum Lang {
  IS = 'is',
  EN = 'en',
  PL = 'pl',
}

export type StepContent = {
  number: number;
  title: string;
};

export type ProgressStepperContent = {
  steps: StepContent[];
};

export type BannerContent = {
  switch: string;
  onBehalf: string;
  loggedInAs: string;
};

export type StringsForOneLanguage = {
  [key: string]: string;
};

export type StringsDataMap = {
  [key: string]: StringsForOneLanguage;
};

export type InvestorUploadContent = {
  buttonText: string;
  radioButtonCaption: string;
  radioButtonDescription: Maybe<Scalars['Json']>;
  documentType: DocumentType;
  key: string;
  uploadDescription: Maybe<Scalars['Json']>;
  uploadTitle: string;
  fileUploadRequired: boolean;
  category: ProfessionalCategoryEnum | string;
};

export type Category = {
  label: string;
  description: string;
  key: InvestorTypeEnum;
};

export enum JustifyContentType {
  FlexStart = 'flex-start',
  Center = 'center',
}

export type PreviewData = {
  ref: string;
};

export interface Variables {
  uId?: string;
  lang?: string;
  tags?: string[];
}

export type APIOptions = {
  previewData?: PreviewData;
  variables?: Variables;
};

export enum NavigationLinkType {
  Button = 'Button',
  BackButton = 'BackButton',
  HomeDarkGrey = 'HomeDarkGrey',
}

export enum CustomOnboardingLinkType {
  Gold = 'gold',
  HomeGold = 'home_gold',
  HomeBlack = 'home_black',
}

export enum ErrorType {
  ERROR_SOMETHING_WENT_WRONG = 'ERROR_SOMETHING_WENT_WRONG',
  ERROR_TIME_EXPIRED_HEADER = 'ERROR_TIME_EXPIRED_HEADER',
  ERROR_TIME_EXPIRED_BODY = 'ERROR_TIME_EXPIRED_BODY',
  ERROR_ELECTRONIC_ID_FAILED = 'ERROR_ELECTRONIC_ID_FAILED',
  ERROR_NUMBER_DOES_NOT_HAVE_EID = 'ERROR_NUMBER_DOES_NOT_HAVE_EID',
  ERROR_EMPTY_FIELD = 'ERROR_EMPTY_FIELD',
  ERROR_INVALID_INPUT_IN_FORM = 'ERROR_INVALID_INPUT_IN_FORM',
  ERROR_FILE_SIZE_EXCEEDED = 'ERROR_FILE_SIZE_EXCEEDED',
  ERROR_FORBIDDEN_FILE_EXTENSION = 'ERROR_FORBIDDEN_FILE_EXTENSION',
  ERROR_OCCURRED = 'ERROR_OCCURRED',
  ERROR_USER_CANCELLATION = 'ERROR_USER_CANCELLATION',
  ERROR_USER_ALREADY_SIGNED = 'ERROR_USER_ALREADY_SIGNED',
  ERROR_SOAPRA_MIGRATION = 'ERROR_SOAPRA_MIGRATION',
  ERROR_SYSTEM_UPDATE = 'ERROR_SYSTEM_UPDATE',
  ERROR_SORRY_FOR_THE_TROUBLE = 'ERROR_SORRY_FOR_THE_TROUBLE',
  ERROR_SYSTEM_DISRUPTION = 'ERROR_SYSTEM_DISRUPTION',
}

export enum ServerErrors {
  PhoneNumberHasNoElectronicId = 'No electronic ID associated with the phone',
  NotFound = 'Onboarding form not found',
  UserCancelation = 'User cancellation',
  AlreadySigned = 'You have already signed this document',
  BeneficialMismatch = 'Mismatched beneficial owners info',
}

export enum ErrorCodes {
  PhoneNumberHasNoElectronicId = 13,
}
export interface ChoiceGroupContent {
  id: string;
  nameEN: string;
  nameIS: string;
  choices: ChoiceContent[];
}
export interface ChoiceContent {
  id: string;
  nameEN: string;
  nameIS: string;
  allowTax?: boolean;
}

export type OnboardingFileUpload = {
  key: string;
  files: File[];
  documentType: DocumentType;
  category: string;
  requiresUpload: boolean;
};

export type ServiceStatusMode = {
  serviceStatus?: DisruptionTypeEnum;
  message?: string;
  showAfter?: Date;
};
