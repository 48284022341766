import { UnknownObject } from '../types/Types';

declare const window: UnknownObject;
declare const global: UnknownObject;

export enum SegmentTrackingId {
  LoginStarted = 'User Login Inititated',
  LoginCompleted = 'User Login Completed',
  LoginFailed = 'User Login Failed',
  Logout = 'User Logged Out',
  LanguageChangedTo = 'Language Changed To XX',
  PersonalInformation = 'User Confirm Information',
  StartSurveyAml = 'User Survey AML',
  StartSurveyAppr = 'User Survey Appropriateness',
  Categorization = 'Choose User Category',
  Agreement = 'User Asset Creation Agreement',
  TermsApprove = 'Approve Terms And Information',
  DocumentSigning = 'Document Signing',
  OnboardingStarted = ' User Onboarding Initiated',
  OnboardingCompleted = 'User Onboarding Completed',
  InappropriateFundModal = 'Inappropriate Fund Modal was triggered',
  RelinquishRightsModal = 'Relinquish Rights Modal was triggered',

  BuyAssetInitiated = 'Buy Asset Initiated',
  BuyAssetSuccess = 'Buy Asset Success',
  BuyAssetFailed = 'Buy Asset Failed',
}

export enum SegmentPageId {
  Home = 'Home',
}

export type AnalyticsEvent = {
  event: SegmentTrackingId | SegmentPageId;
  properties?: Record<string, unknown>;
};

export const trackPage = (url: string, properties?: Record<string, unknown>): void => {
  window.analytics.page(url, properties);
};

export const trackEvent = (analyticsEvent: AnalyticsEvent): void => {
  global.analytics.track(analyticsEvent.event, analyticsEvent.properties);
};

export const identifyUser = (guid: string, traits?: Record<string, unknown>): void => {
  global.analytics.identify(guid, traits);
};
