import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanySchema } from '@kvika/api-types';
import { RootState } from '.';

export interface CompaniesState {
  companies: CompanySchema[];
}

const initialState: CompaniesState = {
  companies: [],
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompanyState: (state, action: PayloadAction<CompaniesState>) => {
      const { companies } = action.payload;
      return { companies };
    },
    resetCompanyState: () => {
      return initialState;
    },
  },
});

export const { setCompanyState, resetCompanyState } = companiesSlice.actions;

export const selectCompaniesState = (state: RootState): CompaniesState => state.companies;

export default companiesSlice.reducer;
