import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddressSchema } from '@kvika/api-types';
import { RootState } from '.';
import { Lang } from '../types/Types';

export interface LoginSuccessfulPayload {
  ssn: string;
  name: string;
  email: string;
  address: AddressSchema;
  phoneNumber: string;
}

interface SessionState {
  loggedIn: boolean;
  user: {
    ssn: string;
    name: string;
    email: string;
    address: AddressSchema;
    phoneNumber: string;
  };
  lang: Lang;
  fundName?: string;
}

const initialState: SessionState = {
  loggedIn: false,
  user: {
    ssn: '',
    name: '',
    email: '',
    address: {
      country: { id: '', nameEN: '', nameIS: '', allowTax: false },
      isLivingAbroad: false,
    },
    phoneNumber: '',
  },
  lang: Lang.IS,
  fundName: undefined,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    loginSuccessful: (state, action: PayloadAction<LoginSuccessfulPayload>) => {
      return {
        ...state,
        loggedIn: true,
        user: action.payload,
      };
    },
    logout: () => {
      return initialState;
    },
    updateLanguage(state, action: PayloadAction<Lang>) {
      return { ...state, lang: action.payload };
    },
    updateFundName(state, action: PayloadAction<string>) {
      return { ...state, fundName: action.payload };
    },
  },
});

export const { loginSuccessful, logout, updateLanguage, updateFundName } = sessionSlice.actions;

export const selectSessionState = (state: RootState): SessionState => state.session;

export default sessionSlice.reducer;
