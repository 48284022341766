import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { logout } from './session';
import { ErrorData, ErrorType } from '../types/Types';

interface DisplayError {
  showErrorModal?: boolean;
  modalErrorBodyKey?: ErrorType;
  modalErrorHeaderKey?: ErrorType;
  errorStatus?: number;
  modalCustomErrorBodyText?: string;
}

interface ErrorState {
  showErrorModal?: boolean;
  modalErrorBodyKey?: ErrorType;
  modalErrorHeaderKey?: ErrorType;
  errorStatus?: number;
  modalCustomErrorBodyText?: string;
  error?: ErrorData;
}

const initialState: ErrorState = {
  showErrorModal: false,
};

export const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    hideErrorModal: (state) => {
      return { ...state, showErrorModal: false };
    },
    displayError: (_, action: PayloadAction<DisplayError>) => {
      return {
        showErrorModal: action.payload.showErrorModal,
        modalErrorBodyKey: action.payload.modalErrorBodyKey,
        modalErrorHeaderKey: action.payload.modalErrorHeaderKey,
        errorStatus: action.payload.errorStatus,
        modalCustomErrorBodyText: action.payload.modalCustomErrorBodyText,
      };
    },
    updateError: (state, action: PayloadAction<ErrorData>) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    clearErrorState: (state) => {
      return {
        ...state,
        errorStatus: undefined,
        modalErrorBodyKey: undefined,
        modalErrorHeaderKey: undefined,
        showErrorModal: false,
        modalCustomErrorBodyText: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return initialState;
    });
  },
});

export const { displayError, updateError, clearErrorState, hideErrorModal } = errorsSlice.actions;

export const selectShowErrorModal = (state: RootState): boolean => Boolean(state.errors.showErrorModal);
export const selectModalErrorBodyKey = (state: RootState): ErrorType | undefined => state.errors.modalErrorBodyKey;
export const selectModalErrorHeaderKey = (state: RootState): ErrorType | undefined => state.errors.modalErrorHeaderKey;
export const selectCustomErrorBodyText = (state: RootState): string | undefined =>
  state.errors.modalCustomErrorBodyText;
export const selectError = (state: RootState): ErrorData | undefined => state.errors.error;

export default errorsSlice.reducer;
