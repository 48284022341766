import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddressSchema } from '@kvika/api-types';

import { RootState } from '.';
import { logout } from './session';

export interface EntityState {
  ssn: string;
  name: string;
  email: string;
  address: AddressSchema;
  phoneNumber: string;
  isCompany: boolean;
}

const initialState: EntityState = {
  ssn: '',
  name: '',
  email: '',
  address: {
    country: { id: '', nameEN: '', nameIS: '', allowTax: false },
    isLivingAbroad: false,
  },
  phoneNumber: '',
  isCompany: false,
};

export const entitySlice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    setEntityState: (state, action: PayloadAction<Partial<EntityState>>) => {
      const { payload } = action;
      const { ssn, name, address, email, phoneNumber, isCompany } = payload;
      state.ssn = ssn ?? state.ssn;
      state.name = name ?? state.name;
      state.address = address ?? state.address;
      state.email = email ?? state.email;
      state.phoneNumber = phoneNumber ?? state.phoneNumber;
      state.isCompany = isCompany ?? state.isCompany;
    },
    resetEntityState: () => {
      return initialState;
    },
    updateEmailAddress: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    updateAddress: (state, action: PayloadAction<AddressSchema>) => {
      state.address = action.payload;
    },
    updatePhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return initialState;
    });
  },
});

export const { setEntityState, resetEntityState, updateEmailAddress, updateAddress, updatePhoneNumber } =
  entitySlice.actions;

export const selectEntityState = (state: RootState): EntityState => state.entity;

export default entitySlice.reducer;
