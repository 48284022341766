import * as React from 'react';
import { PrismicProvider } from '@prismicio/react';
import Paragraph from './Paragraph';
import ExternalLink from './ExternalLink';

type Props = {
  children: React.ReactNode;
};

const richTextlinkResolver = (link: { link_type: string; uid?: string; url?: string }): string => {
  switch (link.link_type) {
    case 'Document': {
      return `/${link.uid ?? ''}`;
    }
    case 'Web': {
      return link.url ?? '';
    }
    default:
      return '';
  }
};

const Provider = ({ children }: Props) => {
  return (
    <PrismicProvider
      linkResolver={richTextlinkResolver}
      richTextComponents={{
        paragraph: Paragraph,
      }}
      externalLinkComponent={ExternalLink}
    >
      {children}
    </PrismicProvider>
  );
};

export default Provider;
