import * as React from 'react';
import { LinkProps } from '@prismicio/react';
import styled from 'styled-components';
import { Colors, Grid } from '@kvika/theme';

const StyledLink = styled.a`
  font-size: inherit;
  margin-top: ${Grid['32px']};
  text-decoration: underline;
  color: ${Colors.gold600};
  cursor: pointer;
`;

const ExternalLink = ({ target, rel = 'noreferrer', href, children }: LinkProps) => {
  return (
    <StyledLink target={target} rel={rel} href={href}>
      {children}
    </StyledLink>
  );
};

export default ExternalLink;
