import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CapitalMarketsOnboardingSchema } from '@kvika/api-types';

import { RootState } from '.';
import { logout } from './session';

export type AnswerStateType = {
  onboardingAnswers: CapitalMarketsOnboardingSchema | undefined;
  isDone: boolean;
};
interface AnswerState {
  answerState: AnswerStateType;
}

const initialState: AnswerState = {
  answerState: {
    isDone: true,
    onboardingAnswers: undefined,
  },
};

export const answerSlice = createSlice({
  name: 'answer',
  initialState,
  reducers: {
    clearState: (state) => {
      state.answerState.onboardingAnswers = initialState.answerState.onboardingAnswers;
    },
    setOnboardingAnswers: (state, action: PayloadAction<CapitalMarketsOnboardingSchema>) => {
      state.answerState.onboardingAnswers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return initialState;
    });
  },
});

export const { clearState, setOnboardingAnswers } = answerSlice.actions;

export const selectAnswerState = (state: RootState): AnswerState => state.answer;
export const selectOnboardingAnswers = (state: RootState): CapitalMarketsOnboardingSchema | undefined =>
  state.answer.answerState.onboardingAnswers;

export default answerSlice.reducer;
