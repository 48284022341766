import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { HttpExceptionType } from '@kvika/api-types';
import { displayError } from '../store/error';
import { logout } from '../store/session';
import { selectStrings } from '../store/string';
import { ErrorData, ErrorType, ServerErrors } from '../types/Types';
import { getErrorEvent } from '../utils/Utils';

export const useApiError = (error?: ErrorData): void => {
  const dispatch = useDispatch();
  const router = useRouter();
  const strings = useSelector(selectStrings);

  const displayErrorModal = (
    modalErrorHeaderKey: ErrorType,
    modalErrorBodyKey: ErrorType,
    modalCustomErrorBodyText?: string
  ) => {
    dispatch(
      displayError({
        showErrorModal: true,
        modalErrorHeaderKey,
        modalErrorBodyKey,
        modalCustomErrorBodyText,
      })
    );
  };

  useEffect(() => {
    if (strings && error) {
      // TODO: Migrate to new Sentry implementation https://github.com/kvika/onboarding-web/issues/731
      captureException(new Error('Api error ocurred'), getErrorEvent(error));

      if (error?.status === 401) {
        dispatch(logout());
        displayErrorModal(ErrorType.ERROR_TIME_EXPIRED_HEADER, ErrorType.ERROR_TIME_EXPIRED_BODY);
        router.push('/');
      } else if (error.data) {
        // Errors that require checking error.data
        if (error.status === 406 && error.data.detail === ServerErrors.UserCancelation) {
          displayErrorModal(ErrorType.ERROR_SOMETHING_WENT_WRONG, ErrorType.ERROR_USER_CANCELLATION);
        } else if (
          error.status === 503 &&
          (error.data.type === HttpExceptionType.MAINTENANCE_MODE ||
            error.data.type === HttpExceptionType.SOPRA_MIGRATION)
        ) {
          const { detail } = error.data;

          if (typeof detail === 'string') {
            displayErrorModal(ErrorType.ERROR_SOMETHING_WENT_WRONG, ErrorType.ERROR_SYSTEM_UPDATE, detail);
          } else {
            displayErrorModal(ErrorType.ERROR_SOMETHING_WENT_WRONG, ErrorType.ERROR_SYSTEM_UPDATE);
          }
        } else {
          displayErrorModal(ErrorType.ERROR_OCCURRED, ErrorType.ERROR_SOMETHING_WENT_WRONG);
        }
      } else {
        displayErrorModal(ErrorType.ERROR_OCCURRED, ErrorType.ERROR_SOMETHING_WENT_WRONG);
      }
    }

    // eslint-disable-next-line
  }, [error, dispatch, strings]);
};
