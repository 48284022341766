import React, { FunctionComponent } from 'react';
import Router from 'next/router';
import styled from 'styled-components';
import { setUser } from '@sentry/nextjs';
import { trackPage } from '../utils/Analytics';
import { getExternalId } from '../utils/AuthenticationStorage';

const StyledPage = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Track client-side page views with Segment
Router.events.on('routeChangeComplete', (url) => {
  trackPage(url, { externalId: getExternalId() });
  setUser({ externalId: getExternalId() });
});

type Props = {
  children: JSX.Element | JSX.Element[];
};

const Page: FunctionComponent<Props> = ({ children }: Props) => <StyledPage>{children}</StyledPage>;

export default Page;
