import { Dispatch } from 'react';
import { AxiosError, AxiosResponseHeaders } from 'axios';
import { setTag, setUser } from '@sentry/nextjs';
import ApiClient, { ApiError } from '@kvika/api-client';
import {
  CapitalMarketsOnboardingSchema,
  SurveyType,
  CapitalMarketsOnboardingResponseSchema,
  InvestorTypeEnum,
  DocumentSchema,
  Language,
  OnboardingType,
} from '@kvika/api-types';
import { AnyAction } from 'redux';
import { updateError } from '../store/error';
import { Maybe, OnboardingPageBodyModalgroupFields, Scalars } from '../types/PrismicTypes';
import { ErrorData, Lang } from '../types/Types';
import { getAuthenticationToken, getExternalId, setAuthenticationToken } from './AuthenticationStorage';
import { RichTextContent } from './Prismic/PrismicHtmlTypes';

export type OnboardingURLParams = {
  phone: string;
  token: string;
  flow: OnboardingType;
  isCompany: string;
  companySsn?: string;
};

export const getTier = (): 'production' | 'staging' => {
  const TIER = process.env.NEXT_PUBLIC_FUNDS_ENV || '';
  if (TIER === 'production') {
    return TIER;
  }
  return 'staging';
};

export const isProduction = (): boolean => getTier() === 'production';
export const isStaging = (): boolean => getTier() === 'staging';

export const getLanguageStringFromEnum = (lang: Lang): string => {
  switch (lang) {
    case Lang.EN:
      return 'English';

    case Lang.PL:
      return 'Polskie';

    default:
      return 'Íslenska';
  }
};

export const isUnauthorized = (statusCode?: number): boolean => {
  return statusCode === 401;
};

export const logOutUnauthorized = (logOut: () => void, statusCode?: number): void => {
  if (isUnauthorized(statusCode)) {
    logOut();
  }
};

export const getKvikaApiClient = (onResponseHeaders?: (headers: AxiosResponseHeaders) => void): ApiClient => {
  return new ApiClient({
    apiUrl: process.env.NEXT_PUBLIC_BASE_URL ?? '',
    appToken: process.env.NEXT_PUBLIC_X_AUTH_TOKEN ?? '',
    authToken: getAuthenticationToken() ?? '',
    storeAuthToken: setAuthenticationToken,
    ...(onResponseHeaders && { onResponseHeaders }),
  });
};

export const getUpdatedOnboardingAnswers = (
  ssn: string,
  lang: Lang,
  onboardingAnswers?: CapitalMarketsOnboardingSchema,
  surveyType?: SurveyType,
  surveyId?: number
): CapitalMarketsOnboardingSchema => {
  const result = onboardingAnswers
    ? { ...onboardingAnswers }
    : ({
        isComplete: false,
        ssn,
        language: getISOLang(lang),
      } as CapitalMarketsOnboardingSchema);

  if (surveyType && surveyId) {
    if (surveyType === SurveyType.Aml) {
      result.amlSurveyAnswerId = surveyId;
    } else if (surveyType === SurveyType.Appropriateness) {
      result.appropriatenessSurveyAnswerId = surveyId;
    }
  }
  return result;
};

export const getOnboardingModal = (
  modals: OnboardingPageBodyModalgroupFields[],
  key: InvestorTypeEnum | string,
  templateVariables?: Record<string, string>
): OnboardingPageBodyModalgroupFields | undefined => {
  const modal = modals.find((modal) => modal.key === key);
  if (templateVariables && modal) {
    return {
      ...modal,
      modal_text: getTextWithTemplateVariables(modal.modal_text as [], templateVariables),
    };
  }
  return modal;
};

const getTemplateVariableKeysWithCurlys = (templateVariables: Record<string, string>) => {
  const keys = Object.keys(templateVariables);
  return keys.map((key) => `{${key}}`);
};

export const getTextWithTemplateVariables = (
  text: RichTextContent[],
  templateVariables: Record<string, string>
): Maybe<Scalars['Json']> => {
  const keys = getTemplateVariableKeysWithCurlys(templateVariables);
  const regex = new RegExp(keys.join('|'), 'g');
  return text.map((textItem) => {
    return {
      ...textItem,
      text: textItem.text?.replace(regex, (match) => {
        const matchWithoutCurly = match.replace('{', '').replace('}', '');
        return templateVariables[matchWithoutCurly];
      }),
    };
  });
};

export const sanitizeOnboardingAnswers = (
  response: CapitalMarketsOnboardingResponseSchema,
  lang: Lang
): CapitalMarketsOnboardingSchema => {
  return {
    isComplete: response.isComplete,
    ssn: response.ssn ?? '',
    amlSurveyAnswerId: response.amlSurveyAnswerId,
    appropriatenessSurveyAnswerId: response.appropriatenessSurveyAnswerId,
    investorType: response.investorType,
    bankAccountNumber: response.bankAccountNumber,
    professionalDocuments: response.professionalDocuments ?? undefined,
    companyInfo: response.companyInfo,
    language: getISOLang(lang),
  };
};

export const shouldDisplayLanguagePicker = (isLanguagePickerEnabled: boolean): boolean => {
  return isStaging() && isLanguagePickerEnabled;
};

export const shouldDisplayClearAnswers = (isClearAnswersEnabled: boolean): boolean => {
  return isStaging() && isClearAnswersEnabled;
};

export const onAxiosError = (error: AxiosError, dispatch: Dispatch<AnyAction>) => {
  dispatch(updateError(error));
};

export type OnboardingStatus = {
  isComplete: boolean;
  investorType?: InvestorTypeEnum;
  isSubmitted: boolean;
  isRiskBlocked: boolean;
  isPepBlocked: boolean;
  isConfirmationBlocked: boolean;
  actingRetail: boolean;
  documents?: DocumentSchema[];
  isNotFound?: boolean;
  isBeneficialOwnersBlocked?: boolean;
};

export const getOnboardingStatus = async (
  ssn: string,
  onResponseHeaders?: (headers: AxiosResponseHeaders) => void
): Promise<OnboardingStatus> => {
  const capitalMarketsStatus = await getCapitalMarketsOnboardingStatus(ssn, onResponseHeaders);
  const privateBankingStatus = await getPrivateBankingOnboardingStatus(ssn, onResponseHeaders);

  return {
    isComplete: capitalMarketsStatus.isComplete || privateBankingStatus.isComplete,
    investorType: capitalMarketsStatus.investorType || privateBankingStatus.investorType,
    isSubmitted: capitalMarketsStatus.isSubmitted || privateBankingStatus.isSubmitted,
    isRiskBlocked: capitalMarketsStatus.isRiskBlocked || privateBankingStatus.isRiskBlocked,
    isPepBlocked: capitalMarketsStatus.isPepBlocked || privateBankingStatus.isPepBlocked,
    isConfirmationBlocked: capitalMarketsStatus.isConfirmationBlocked || privateBankingStatus.isConfirmationBlocked,
    actingRetail: capitalMarketsStatus.actingRetail || privateBankingStatus.actingRetail,
    documents: capitalMarketsStatus.documents || privateBankingStatus.documents,
    isNotFound: capitalMarketsStatus.isNotFound && privateBankingStatus.isNotFound,
    isBeneficialOwnersBlocked:
      capitalMarketsStatus.isBeneficialOwnersBlocked || privateBankingStatus.isBeneficialOwnersBlocked,
  };
};
export const getCapitalMarketsOnboardingStatus = async (
  ssn: string,
  onResponseHeaders?: (headers: AxiosResponseHeaders) => void
): Promise<OnboardingStatus> => {
  try {
    const apiClient = getKvikaApiClient(onResponseHeaders);
    const {
      isComplete,
      investorType,
      isSubmitted,
      actingRetail,
      isPepBlocked,
      isRiskBlocked,
      isConfirmationBlocked,
      isBeneficialOwnersBlocked,
      documents,
    } = await apiClient.getCapitalMarketsOnboarding(ssn);
    return {
      isComplete,
      investorType,
      isSubmitted,
      actingRetail,
      isPepBlocked,
      isRiskBlocked,
      isConfirmationBlocked,
      isBeneficialOwnersBlocked,

      documents,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return {
      isComplete: false,
      isSubmitted: false,
      actingRetail: false,
      isPepBlocked: false,
      isRiskBlocked: false,
      isConfirmationBlocked: false,
      documents: [],
      isNotFound: e.response && e.response.status === 404,
    };
  }
};

export const getPrivateBankingOnboardingStatus = async (
  ssn: string,
  onResponseHeaders?: (headers: AxiosResponseHeaders) => void
): Promise<OnboardingStatus> => {
  try {
    const apiClient = getKvikaApiClient(onResponseHeaders);
    const {
      isComplete,
      investorType,
      isSubmitted,
      actingRetail,
      isPepBlocked,
      isRiskBlocked,
      isConfirmationBlocked,
      isBeneficialOwnersBlocked,
      documents,
    } = await apiClient.getPrivateBankingOnboarding(ssn);
    return {
      isComplete,
      investorType,
      isSubmitted,
      actingRetail,
      isPepBlocked,
      isRiskBlocked,
      isConfirmationBlocked,
      isBeneficialOwnersBlocked,
      documents,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return {
      isComplete: false,
      isSubmitted: false,
      actingRetail: false,
      isPepBlocked: false,
      isRiskBlocked: false,
      isConfirmationBlocked: false,
      documents: [],
      isNotFound: e.response && e.response.status === 404,
    };
  }
};

export const getISOLang = (lang: Lang): Language => {
  switch (lang) {
    case Lang.IS:
      return Language.IsIS;
    case Lang.EN:
      return Language.EnUS;
    default:
      return Language.IsIS;
  }
};

export const getAge = (ssn: string) => {
  const currentYear = new Date().getFullYear();
  const yearBorn = ssn.substring(4, 6);
  const century = ssn.substring(9, 10);
  let fullYearBorn;

  if (century === '9') {
    fullYearBorn = 1900 + parseInt(yearBorn, 10);
  } else {
    fullYearBorn = 2000 + parseInt(yearBorn, 10);
  }

  const age = currentYear - fullYearBorn;

  return age;
};

export const getOnboardingUrl = (token: string, phoneNumber: string, isCompany: boolean, companySsn?: string) => {
  const paramsObject: OnboardingURLParams = {
    phone: phoneNumber,
    token,
    flow: OnboardingType.CAPITAL_MARKETS,
    isCompany: isCompany ? 'true' : 'false',
  };
  if (companySsn) {
    paramsObject.companySsn = companySsn ?? '';
  }
  const params = new URLSearchParams(paramsObject);

  const baseUrl = `${process.env.NEXT_PUBLIC_ONBOARDING_URL}/#?${params.toString()}`;

  return baseUrl;
};

export const parseApiError = (error: ApiError): ErrorData => {
  // This is needed due to redux complaining when we pass the raw error which contains functions and other
  // non-serializable objects, right now we only need the request id header so we only include that
  // https://redux.js.org/faq/actions#why-should-type-be-a-string-or-at-least-serializable-why-should-my-action-types-be-constants

  const headers = {
    requestId: error.response?.headers['x-request-id'],
  };

  return {
    data: error.response?.data,
    status: error.response?.status,
    headers,
  };
};

export const getErrorEvent = (apiError: ErrorData, extra?: Record<string, unknown>) => {
  if (apiError) {
    const { status, data, headers } = apiError;
    if (data && headers) {
      const { requestId } = headers;
      setTag('requestId', requestId);
      // Setting this on login doesn't work so we set it before the error is sent
      setUser({ id: getExternalId() });
      const { type, code, detail } = data;
      const detailString = typeof detail === 'string' ? detail : JSON.stringify(detail);
      return {
        message: `${detailString}, errorCode: ${status}}`,
        extra: { code, type, requestId, ...extra },
      };
    }
  }
  // Reset tag if we have a unexpected error so that it doesn't get the requestId tag from the previous error
  setUser(null);
  setTag('requestId', undefined);
  return { message: `An unexpected error occurred}`, extra };
};
