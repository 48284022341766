import * as React from 'react';

type Props = {
  children: JSX.Element[];
  key: string;
};

const Paragraph = ({ children, key }: Props) => {
  if (children && children[0].props?.children[0].props.children === '') {
    return (
      <React.Fragment key={key}>
        {children}
        <br />
      </React.Fragment>
    );
  }
  return <p key={key}>{children}</p>;
};

export default Paragraph;
