import { createGlobalStyle } from 'styled-components';
import { Typography } from '@kvika/theme';

export const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }

    html {
        padding: 0;
        margin: 0;
    }
    
    body {
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-style: normal;
        font-weight: normal;
    }

    @font-face {
        font-family: ACaslonPro;
        src: url('/fonts/ACaslonPro-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: ACaslonPro;
        font-weight: 600;
        src: url('/fonts/ACaslonPro-Semibold.woff2') format('woff2');
    }

    @font-face {
        font-family: ACaslonPro;
        font-weight: bold;
        src: url('/fonts/ACaslonPro-Bold.woff') format('woff');
    }

    @font-face {
        font-family: ACaslonPro;
        font-weight: normal;
        font-style: italic;
        src: url('/fonts/ACaslonPro-Italic.woff') format('woff');
    }

    @font-face {
        font-family: AkzidenzGroteskPro;
        src: url('/fonts/AkzidenzGroteskPro-Regular.otf') format('opentype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: AkzidenzGroteskPro;
        src: url('/fonts/AkzidenzGroteskPro-Light.otf') format('opentype');
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: AkzidenzGroteskPro;
        src: url('/fonts/AkzidenzGroteskPro-Md.otf') format('opentype');
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: AkzidenzGroteskPro;
        src: url('/fonts/AkzidenzGroteskPro-Bold.otf') format('opentype');
        font-weight: bold;
        font-style: normal;
    }

    @font-face {
        font-family: AkzidenzGroteskPro;
        src: url('/fonts/AkzidenzGroteskPro-It.otf') format('opentype');
        font-weight: normal;
        font-style: italic;
    }


    ${Typography};
`;
