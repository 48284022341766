import { AppProps } from 'next/app';
import React, { FunctionComponent } from 'react';
import Modal, { ModalHeader, ModalBody, ModalSize } from '@kvika/modal';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { GlobalStyles } from '../styles/GlobalStyles';
import Page from '../components/Page';
import { useApiError } from '../hooks/useApiError';
import store from '../store';
import {
  clearErrorState,
  hideErrorModal,
  selectCustomErrorBodyText,
  selectError,
  selectModalErrorBodyKey,
  selectModalErrorHeaderKey,
  selectShowErrorModal,
} from '../store/error';
import { selectSessionState } from '../store/session';
import { selectStrings } from '../store/string';
import { getStringFromKey } from '../utils/Languages';
import { ErrorType } from '../types/Types';
import PrismicProvider from '../components/prismic/PrismicProvider';

interface AppErrorProps {
  err: unknown;
}

const GlobalModal: FunctionComponent = () => {
  const dispatch = useDispatch();
  const showModal = useSelector(selectShowErrorModal);
  const modalErrorBodyKey = useSelector(selectModalErrorBodyKey);
  const modalErrorHeaderKey = useSelector(selectModalErrorHeaderKey);
  const modalCustomErrorBodyText = useSelector(selectCustomErrorBodyText);
  const { lang } = useSelector(selectSessionState);
  const strings = useSelector(selectStrings);

  const getModalTitle = () => {
    return modalErrorHeaderKey ? getString(modalErrorHeaderKey) : getString(ErrorType.ERROR_OCCURRED);
  };

  const getString = (key?: ErrorType) => {
    if (strings && key) {
      return getStringFromKey({ lang, strings, key });
    }
    return undefined;
  };

  const onClose = () => {
    dispatch(hideErrorModal());
  };

  const renderModalBody = () => {
    if (modalCustomErrorBodyText) {
      return <div>{modalCustomErrorBodyText}</div>;
    }

    return <>{getString(modalErrorBodyKey)}</>;
  };
  return (
    <Modal
      onExitAnimationEnd={() => dispatch(clearErrorState())}
      size={ModalSize.SMALL}
      isVisible={showModal}
      onClose={onClose}
    >
      <ModalHeader>{getModalTitle()}</ModalHeader>
      <ModalBody>{renderModalBody()}</ModalBody>
    </Modal>
  );
};

const ErrorHandler: FunctionComponent = () => {
  const error = useSelector(selectError);
  useApiError(error);
  return null;
};

const App: FunctionComponent<AppProps & AppErrorProps> = ({ Component, pageProps, err }: AppProps & AppErrorProps) => {
  return (
    <Provider store={store}>
      <GlobalStyles />
      <PersistGate loading={null} persistor={persistStore(store)}>
        <Page>
          <GlobalModal />
          <ErrorHandler />
          <PrismicProvider>
            <Component {...pageProps} err={err} />
          </PrismicProvider>
        </Page>
      </PersistGate>
    </Provider>
  );
};

export default App;
