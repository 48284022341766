import { CountrySchema } from '@kvika/api-types';
import { StringsDataMap } from '../types/Types';

export enum Lang {
  IS = 'is',
  EN = 'en',
  PL = 'pl',
}

type getTranslationType = (lang: Lang, is: string, en: string, pl: string) => string;

export const getTranslation: getTranslationType = (lang, is, en, pl) => {
  switch (lang) {
    case Lang.IS:
      return is;
    case Lang.EN:
      return en;
    case Lang.PL:
      return pl;
    default:
      return is;
  }
};

export const getStringFromKey = ({ strings, lang, key }: { strings: StringsDataMap; lang: Lang; key: string }) => {
  return key ? strings[lang][key] : '';
};

export const getLanguageKey = (lang: Lang): keyof CountrySchema => {
  return lang === Lang.IS ? 'nameIS' : 'nameEN';
};
